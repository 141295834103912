import articleIcon from './article.js';
import categoryIcon from './category.js';
import continentIcon from './continent.js';
import currencyIcon from './currency.js';
import filterIcon from './filter.js';
import genderIcon from './gender.js';
import inboxIcon from './inbox.js';
import matureContentIcon from './matureContent.js';
import menuIcon from './menu.js';
import messageIcon from './message.js';
import paymentMethodIcon from './paymentMethod.js';
import peopleIcon from './people.js';
import personalTypeIcon from './personalType.js';
import postIcon from './post.js';
import promoIcon from './promo.js';
import schemeIcon from './scheme.js';
import shareIcon from './share.js';
import socialIcon from './social.js';
import toggleIcon from './toggle.js';
import uploadIcon from './upload.js';
import userIcon from './user.js';
import verifiedIcon from './verified.js';
import visibilityIcon from './visibility.js';

const addIcon = 'plus';
const removeIcon = 'trash';
const redoIcon = 'rotate';
const undoIcon = 'rotateLeft';
const copyIcon = 'copy';

const enterIcon = 'rightToBracket';
const exitIcon = 'rightFromBracket';

const openIcon = 'arrowUpRightFromSquare';
const closeIcon = 'xmark';

const backwardIcon = 'anglesLeft';
const forwardIcon = 'anglesRight';
const downIcon = 'angleDown';

const settingsIcon = 'gear';

const timerIcon = 'timer';

const coinsIcon = 'coins';

const receiptIcon = 'receipt';
const clockIcon = 'clock';

const idIcon = 'idBadge';

const statusIcon = 'arrowsRotate';

const emailIcon = 'at';
const envelopeIcon = 'envelope';

const globeIcon = 'globe';

const downloadIcon = 'download';
const addressIcon = 'addressCard';
const medalIcon = 'medal';
const champagneIcon = 'champagneGlasses';
const flameIcon = 'fireFlameCurved';
const bellIcon = 'bell';
const mobileIcon = 'mobile';
const magnifyIcon = 'magnifyingGlass';
const imagesIcon = 'images';
const historyIcon = 'history';
const briefcaseIcon = 'briefcaseBlank';
const lockIcon = 'lock';
const boxOpenIcon = 'boxOpen';
const tagIcon = 'tag';
const badgePercentIcon = 'badgePercent';
const rightLeftIcon = 'rightLeft';
const languageIcon = 'language';
const handshakeIcon = 'handshakeSimple';
const calendarRangeIcon = 'calendarRange';
const linkIcon = 'link';
const blogIcon = 'blog';
const listOlIcon = 'listOl';

const phoneIcon = 'phone';

export {
  addIcon,
  addressIcon,
  articleIcon,
  backwardIcon,
  badgePercentIcon,
  bellIcon,
  blogIcon,
  boxOpenIcon,
  briefcaseIcon,
  calendarRangeIcon,
  categoryIcon,
  champagneIcon,
  clockIcon,
  closeIcon,
  coinsIcon,
  continentIcon,
  copyIcon,
  currencyIcon,
  downIcon,
  downloadIcon,
  emailIcon,
  enterIcon,
  envelopeIcon,
  exitIcon,
  filterIcon,
  flameIcon,
  forwardIcon,
  genderIcon,
  globeIcon,
  handshakeIcon,
  historyIcon,
  idIcon,
  imagesIcon,
  inboxIcon,
  languageIcon,
  linkIcon,
  listOlIcon,
  lockIcon,
  magnifyIcon,
  matureContentIcon,
  medalIcon,
  menuIcon,
  messageIcon,
  mobileIcon,
  openIcon,
  paymentMethodIcon,
  peopleIcon,
  personalTypeIcon,
  phoneIcon,
  postIcon,
  promoIcon,
  receiptIcon,
  redoIcon,
  removeIcon,
  rightLeftIcon,
  schemeIcon,
  settingsIcon,
  shareIcon,
  socialIcon,
  statusIcon,
  tagIcon,
  timerIcon,
  toggleIcon,
  undoIcon,
  uploadIcon,
  userIcon,
  verifiedIcon,
  visibilityIcon,
};
